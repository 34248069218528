var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c(
        "div",
        { attrs: { id: "header" } },
        [
          _c("div", { staticClass: "ml-3 p-2", attrs: { id: "logo" } }, [
            _c("img", { attrs: { src: _vm.publicPath + "logo.png" } }),
          ]),
          _c("current-user"),
        ],
        1
      ),
      _c("sidebar"),
      _c(
        "div",
        { staticClass: "invite-dash mt-1" },
        [
          _c(
            "b-container",
            { staticStyle: { "max-width": "100%", padding: "0px" } },
            [
              _c(
                "b-row",
                { staticClass: "mb-2", attrs: { "align-v": "center" } },
                [
                  _c("b-col", { attrs: { sm: "12", md: "3" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-weight": "300",
                          "font-size": "36px",
                        },
                      },
                      [_vm._v("Add a user")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-col",
                    { staticClass: "pl-5", attrs: { sm: "12", md: "9" } },
                    [_c("div", [_c("invite-user")], 1)]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { attrs: { id: "footer" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }